import React from 'react';
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import '../styles/style.css'
import NavigationBar from './NavigationBar';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'
import fourZeroFour from '../styles/images/404.png';

class NotFound extends React.Component {

    state = {
        current: 'notfound',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="centerHeader">
                <Row type ="flex" justify="space-around" align="middle">
                    <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                    <Col xs={24} sm={20} md={14} lg={12} xl={10}>
                        <img alt="404" width="100%" src={fourZeroFour}/>
                    </Col>
                    <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    </Col>
                </Row>
                </header>
                <NavigationBar handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}
export default NotFound;
