import React from 'react';
import { Card, Carosel, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import '../styles/style.css'
import logo from '../styles/images/WispberryLogo.png';
import badge from '../styles/images/App_Store_Badge.png';
import NavigationBar from './NavigationBar';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'
import trust from '../styles/images/landing_trust.png';
import share from '../styles/images/landing_share.png';
import rate from '../styles/images/landing_rate.png';
import bye from '../styles/images/landing_bye.png';
import discover from '../styles/images/landing_discover.png';
import CrossfadeImage from '../helper/CrossfadeImage';

const { Meta } = Card;

const mockArray = [require('../styles/images/mock1.jpg'), require('../styles/images/mock2.jpg'), require('../styles/images/mock3.jpg'), require('../styles/images/mock4.jpg')];

class LandingPage extends React.Component {

    constructor() {
        super();
        this.state = { mockIndex: 0 };
      }

      componentDidMount() {
        this.timeout = setInterval(() => {
          let currentIndex = this.state.mockIndex;
          this.setState({ mockIndex: currentIndex + 1 });
        }, 7500);
      }
    
      componentWillUnmount() {
        clearInterval(this.timeout);

      }


    state = {
        current: ' ',
      }

    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        let mockChange = mockArray[this.state.mockIndex % mockArray.length];


        return (
        <div>
            <ScrollToTopOnMount/>
            <header className="centerHeader">
                <Row type ="flex" justify="space-around" align="middle">
                    <Col xs={24} sm={20} md={9} lg={8} xl={7}>
                        <img alt="logo" width="70%" src={logo}/>
                        <h1>Wispberry</h1>
                        <h3>Review your everyday</h3>
                        <a href="https://itunes.apple.com/us/app/wispberry/id1380368170?ls=1&mt=8"><img alt="appstorebutton"width="70%" src={badge}/></a>
                    </Col>
                    <Col xs={0} sm={0} md={9} lg={8} xl={7}>
                        <CrossfadeImage width="100%" src={mockChange} duration={1000} timingFunction={"ease-out"}/>
                    </Col>
                </Row>
            </header>
            <section style={{ background: '#2391ff', padding: "5vh 1.5em" }}>
                <h2>Wispberry is a mobile platform that lets you create and share pictures and videos with context.</h2>
                <h2>Whether it's eating at a restaurant, shopping for clothes, or playing with electronics,<br/>
 express your appreciations or vent your frustrations and inform the community on your experiences.</h2>
            </section>
            <section className="cardSection" style={{ background: '#fff' }}>
                <Row type ="flex" justify="space-around" align="middle">
                    <Col xs={24} sm={12} md={12} lg={12} xl={6} >
                        <Card hoverable style={{ width: 220 }} cover={<img alt="share" src={share} />}>
                            <Meta style={{ textAlign: 'center', padding: '0 0px' }} title="Share" description="photos and videos of your everyday moments"/>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Card hoverable style={{ width: 220 }} cover={<img alt="rate" src={rate} />}>
                            <Meta style={{ textAlign: 'center', padding: '0 20px' }} title="Rate" description="the good, the bad, and the average"/>
                        </Card>
                    </Col>
                    {/* <Col xs={24} sm={16} md={8} lg={8} xl={4}>
                        <Card hoverable style={{ width: 220 }} cover={<img alt="bye" src={bye} />}>
                            <Meta style={{ textAlign: 'center', padding: '0 10px' }} title="Bye" description="to irrelevant or misleading star ratings"/>
                        </Card>
                    </Col> */}
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Card hoverable style={{ width: 220 }} cover={<img alt="trust" src={trust} />}>
                            <Meta style={{ textAlign: 'center', padding: '0 0px' }} title="Trust" description="the individual, not the majority"/>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Card hoverable style={{ width: 220 }} cover={<img alt="discover" src={discover} />}>
                            <Meta style={{ textAlign: 'center', padding: '0 0px' }} title="Discover" description="through individuals' reviews"/>
                        </Card>
                    </Col>
                </Row>

                {/* <Carousel autoplay>
                    <div>
                        <img width="10%" src={share}/>
                        <h1>SHARE</h1>
                        <h2>photos and videos of your everyday moments</h2>
                    </div>
                    <div>
                        <img width="10%" src={rate}/>
                        <h1>RATE</h1>
                        <h2>the good, the bad, and the average</h2>
                    </div>                 
                    <div>
                        <img width="10%" src={bye}/>
                        <h1>BYE</h1>
                        <h2>to irrelevant or misleading star ratings</h2>
                    </div>
                    <div>
                        <img width="10%" src={trust}/>
                        <h1>TRUST</h1>
                        <h2>the individual, not the majority</h2>
                    </div>                 
                    <div>
                        <img width="10%" src={discover}/>
                        <h1>DISCOVER</h1>
                        <h2>through individuals' reviews and dare to experience</h2>
                    </div>                                 
                </Carousel> */}
            </section>
                <NavigationBar handleCurrentPageState={this.handleCurrentPageState} />
        </div>
        );
    }
}
export default LandingPage;
