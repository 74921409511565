import React from 'react';
import 'antd/dist/antd.css';
import '../styles/style.css'
import NavigationBar from './NavigationBar';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

class About extends React.Component {

    state = {
        current: 'about',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="about">
                    <h1>About</h1>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"600", fontSize:"17px", color: "#9BEC43" }}>Origin &nbsp;</span>
                        Wispberry was created in response to the way we utilize reviews today. When we attempt to explore the unknown, we seek shelter under reviews and, by doing so, we have become accustomed to being safe but dispassionate.<br></br><br></br>
                        
                        Don’t get me wrong. It is practical to seek out guidance and recommendations from others. But, more often than not, “others” are the majority and so we drown ourselves in an ocean of reviews as we conform to the majority. By following the majority, we are influenced into a decision, thereby surrendering the opportunity to be independent from the majority and missing out on experiences based on fear but passion.<br></br><br></br>   

                        So if you want to explore the unknown and feel like taking a chance, hop onto Wispberry!
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"600", fontSize:"17px", color: "#9BEC43" }}>Jason, Founder | jason@wispberry.com</span><br></br>
                        Hey reader! I built Wispberry because I wanted a platform where you can review photos and videos of worthwhile moments that you want to let others know about. And rather than it being just a photo or just a video, your reviewed moments become informative experiences that others can benefit from. I hope you enjoy the app!
                    </h5>
                </header>
                <NavigationBar handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}

export default About;