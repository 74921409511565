import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LandingPage from './LandingPage';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import NotFound from './NotFound';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route path="/about" component={About}/>
            <Route path="/privacypolicy" component={PrivacyPolicy}/>
            <Route path="/termsofservice" component={TermsOfService}/>
            <Route component={NotFound}/>
        </Switch>
    </BrowserRouter>
);

export default Router;